<template>
    <b-modal
      id="modal-public-invoice-VAT"
      v-model="isOpen"
      size="md"
      centered
      hide-header
      :no-close-on-backdrop="true"
    >
      <b-card-header
        class="banned-background-color"
      >
        <div class="d-flex justify-content-between align-items-center block-header">
          <h4 class="mb-0">
            Phát hành hóa đơn
          </h4>
          <ez-icon
            icon="ezGolf-icon-x"
            class="cursor-pointer"
            size="24"
            @click="hideModal"
          />
        </div>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="4">
            <b-form-group :label="$t('golf_sidebar_public_invoice_invoice_date')">
                <date-range-picker
                            ref="picker"
                            v-model="data.InvoiceDate"
                            :single-date-picker="true"
                            opens="right"
                            :ranges="false"
                            :time-picker="false"
                            :time-picker-increment="1"
                            :show-dropdowns="true"
                            :auto-apply="true"
                            :locale-data="{
                              format: 'DD/MM/YYYY',
                              daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                              monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                              firstDay: 0
                            }"
                          />
              </b-form-group>
          </b-col>
          <b-col cols="4" v-if="check[0]=='2'">
            <b-form-group label="Mẫu">
              <v-select
                              v-model="data.FormNo"
                              :options="ListFormNo"
                              label="FormNo"
                              :reduce="label => label.FormNo"
                              :clearable="false"
                              :multiple="false"
                              @input="onChangeConfigEivoice()"
                              :placeholder="$t('golf_tax_invoice_formno')"
                              rows="2"
                            />
          </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Ký hiệu">
              <v-select
                              v-model="data.SerialNo"
                              :options="ListSerialNo"
                              label="SerialNo"
                              :reduce="label => label.SerialNo"
                              :clearable="false"
                              :multiple="false"
                              :placeholder="$t('golf_tax_invoice_serialno')"
                              rows="2"
                            />
          </b-form-group>
          </b-col>
          <b-col cols="4"  v-if="check[0]=='1'">
            <b-form-group label="Số hóa đơn">
              <b-form-input
                v-model="data.InvoiceNo"
                placeholder="Nhập số hóa đơn"
                rows="2"
              />
          </b-form-group>
          </b-col>
          
        </b-row>
        

      </b-card-body>
      <template #modal-footer>
      
      <b-button  variant="primary" :disabled="isLoading" @click="PublicInvoice_UIVAT02()">
        {{ $t("golf_sidebar_checkout_public_order") }}
        <b-spinner
          v-if="isLoading"
          small
          label="Loading..."
        />
      </b-button>
      
      <b-button :disabled="isLoading"  variant="outline-primary" @click="openInvoice()">
        {{ $t("golf_sidebar_checkout_print_invoice") }}
        <b-spinner
          v-if="isLoading"
          small
          label="Loading..."
        />
      </b-button>
      <b-button :disabled="isLoading"  variant="secondary" @click="hideModal()">
        {{ $t('golf_common_back') }}
        <b-spinner
          v-if="isLoading"
          small
          label="Loading..."
        />
      </b-button>
    </template>
    </b-modal>
</template>
<script>
import { invoice } from '@/api/invoice'
import { booking } from '@/api/booking'
import { showToast } from '@/@core/utils/utils'
export default {
    props: {
        IssueInvoiceIdProps: {
            type: Object,
            default: null
        },
        dataPayment: {
          type: Array,
          default: null
        }
    },
    data() {
        return {
            isOpen: false,
            invoiceId: null,
            data: {
                Id: null,
                FormNo: null,
                SerialNo: null,
                InvoiceNo: null,
                InvoiceDate: this.getCurrentDateUTC()
            },
            SerialNoDefault: null,
            FormNoDefault: null,
            InvoiceDate: this.getCurrentDateUTC(),
            check: JSON.parse(localStorage.getItem('Einvoice')),
            InvoiceDetail: null,
            isLoading: false,
            ListFormNo:[],
            ListSerialNo:[],
            ListSerialNoAll:[],
        }
    },
    created() {
      this.call_api_RICF01()
    },
    watch: {
        dataPayment(value) {
          if(value) {
            this.data.InvoiceDate = value[0] ? value[0].FiscalPaymentDate : this.getCurrentDateUTC()
          }
        },
        isOpen(value) {
          if(value) {
            this.data.Id=this.IssueInvoiceIdProps.Id
            this.data.SerialNo =  this.SerialNoDefault
            this.data.FormNo = this.FormNoDefault
            this.data.InvoiceNo = null
            this.detailInvocie_RIVAT02()
          }
        }
    },
    methods: {
        hideModal() {
            this.$bvModal.hide('modal-public-invoice-VAT')
            this.data.SerialNo = this.SerialNoDefault
            this.data.InvoiceNo = null
        },
        async PublicInvoice_UIVAT02() {
          this.isLoading = true
            const body = {
                Invoice : this.data
            }
            await invoice.api_UIVAT02(body).then(async res => {
                if(res.Status === "200") {
                  if(this.check[0]=='2') {
                    const response_IIVAT03 = await this.issueEinvocie_IIVAT03(this.data.Id)
                    await this.detailInvocie_RIVAT02()
                    await this.openInvoice()
                    this.isLoading = true
                    this.showResToast(response_IIVAT03)
                    setTimeout(() => {
                    this.$emit('RefreshPayment')
                    this.hideModal()
                    this.isLoading = false
                  }, 1500); // 2000ms = 2 giây
                    
                  } else {
                    this.showResToast(res)
                    this.$emit('RefreshPayment')
                    this.isLoading = false
                  }
                } else {
                  this.showResToast(res)
                  this.isLoading = false
                }
            })
        },
        openInvoice() {
          if(this.check[0]=='2') {
            if(this.InvoiceDetail.EinvoiceStatus == 'PUBLISH') {
              this.$emit('OpenModalEinvoice', {
                data: {
                  GolfInvocieId: this.data.Id,
                  Type: "DownloadPDF"
                }
              })
            } else {
              this.$emit('OpenModalEinvoice', {
                data: {
                  GolfInvocieId: this.data.Id,
                  Type: "DownloadPDFInvoiceDraft"
                }
              })
            }
          } else {
            const routeData = this.$router.resolve({ name: 'InvoiceVAT', params: { id: this.data.Id } })
            window.open(routeData.href, '_blank')
          }
        },
        
        async call_api_RICF01() {
      // Lấy danh sách config
      const response= await invoice.api_RICF01()
          if (response.Status === '200') {
            this.ListFormNo =this.desired_output(response.Data.InvoiceConfig).map(e=>({
              FormNo:e
            }));
            this.ListSerialNoAll =response.Data.InvoiceConfig
            const checkDefaultInvoice = response.Data.InvoiceConfig.filter(x => x.DefaultInvoiceSymbol === true)
            if (checkDefaultInvoice != null && checkDefaultInvoice.length > 0) {
              this.SerialNoDefault = checkDefaultInvoice[0].SerialNo
              this.FormNoDefault = checkDefaultInvoice[0].FormNo
              this.ListSerialNo =this.ListSerialNoAll.filter(x=>x.FormNo==this.FormNoDefault)
            } }
    },
    desired_output(arr){
      let unique_values = arr
          .map((item) => item.FormNo)
          .filter(
              (value, index, current_value) => current_value.indexOf(value) === index
          );
      return unique_values;
  },
    onChangeConfigEivoice(){
      this.ListSerialNo =this.ListSerialNoAll.filter(x=>x.FormNo==this.data.FormNo)
      this.data.SerialNo = this.ListSerialNo[0].SerialNo
    },
        async issueEinvocie_IIVAT03(InvocieGolfId) {
          const body = {
            InvoiceGolfId: InvocieGolfId
          }
          const response =  await invoice.api_IIVAT03(body)
          return response
        },
        async detailInvocie_RIVAT02() {
          this.isLoading = true
            const body = {
                Invoice: {
                    Id: this.data.Id
                }
            }
            const response =  await invoice.api_RIVAT02(body)
            if(response.Status == 200) {
                this.InvoiceDetail = response.Data.Invoice
            }
            this.isLoading = false
        }
    }
}
</script>
<style lang="scss">
#modal-public-invoice-VAT {
  .modal-dialog {
    max-width: 750px;
  }
}
</style>
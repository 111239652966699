import dayjs from 'dayjs'
import { booking } from '@/api/booking'
import { invoice } from '@/api/invoice'
import { commonServices } from '@/api/common-services'
import 'devextreme/dist/css/dx.light.css'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { saveAs } from 'file-saver-es'
import { convertUTC } from '@/@core/utils/utils'
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxHeaderFilter,
  DxGroupPanel,
  DxGrouping,
  DxScrolling,
  DxPaging,
  DxPager,
  DxSummary,
  DxLookup,
  DxTotalItem,
  DxGroupItem,
  DxMasterDetail,
  DxStringLengthRule,
  DxRequiredRule,
  DxRangeRule,
  DxValueFormat,
  DxColumnChooser,
  DxColumnFixing,
  DxSelection,
  DxExport,
} from 'devextreme-vue/data-grid'
import SidebarMergeService from './SidebarMergeService/SidebarMergeService.vue'

const dataGridRef = 'dataGrid'
export default {
  name: 'ListPayment',
  components: {
    SidebarMergeService,
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxFilterRow,
    DxHeaderFilter,
    DxGroupPanel,
    DxGrouping,
    DxScrolling,
    DxPaging,
    DxPager,
    DxSummary,
    DxLookup,
    DxTotalItem,
    DxGroupItem,
    DxMasterDetail,
    DxStringLengthRule,
    DxRangeRule,
    DxRequiredRule,
    DxValueFormat,
    DxColumnChooser,
    DxColumnFixing,
    DxSelection,
    DxExport,
  },
  data() {
    return {
      dataGridRef,
      search: {
        ClientId: [],
        KeyWord: '',
        BDC: [],
        FromDate: this.getUTC('start', 'day', -7),
        ToDate: this.getUTC('start', 'day'),
        PaymentCode: null,
        InvoiceNo: null,
      },
      listPayment: [],
      perPage: 10,
      allMode: 'allPages',
      checkBoxesMode: 'onClick', // 'always' :
      optionPerPage: [10, 20, 50],
      configTable: [
        {
          caption: 'BDC',
          field: 'BDC',
          isShow: true,
          alignment: 'left',
        },
        {
          caption: this.$t('golf_common_full_name'),
          field: 'FullName',
          isShow: true,
          alignment: 'left',
        },
        {
          caption: this.$t('golf_common_number_of_hole'),
          field: 'NumberOfHole',
          isShow: true,
          alignment: 'left',
        },
        {
          caption: 'Tee Time',
          field: 'TeeTime',
          isShow: true,
          alignment: 'left',
          cellTemplate: 'TeeTime',
        },
        {
          caption: 'TA',
          field: 'ClientName',
          isShow: true,
          alignment: 'left',
        },
        {
          caption: this.$t('golf_tax_invoice_payment_code'),
          field: 'PaymentCode',
          isShow: true,
          alignment: 'left',
          cellTemplate: 'PaymentCode',
        },
        {
          caption: this.$t('golf_tax_invoice_booking_code'),
          field: 'Id',
          isShow: true,
          alignment: 'left',
          cellTemplate: 'BookingCode',
        },
        {
          caption: this.$t('golf_tax_invoice_payment_date'),
          field: 'FiscalPaymentDate',
          isShow: true,
          alignment: 'left',
          cellTemplate: 'FiscalPaymentDate',
        },
        {
          caption: this.$t('golf_common_total'),
          field: 'TotalAmount',
          isShow: true,
          cellTemplate: 'TotalAmount',
          alignment: 'right',
        },
        {
          caption: this.$t('golf_common_amount_of_einvoice'),
          field: 'AmountOfEInvoice',
          isShow: true,
          cellTemplate: 'AmountOfEInvoice',
          alignment: 'right',
        },
        {
          caption: this.$t('golf_common_amount_not_of_einvoice'),
          field: 'AmountNotEInvoice',
          isShow: true,
          cellTemplate: 'AmountNotEInvoice',
          alignment: 'right',
        },
        {
          caption: 'Invoice',
          field: 'Invoice',
          isShow: true,
          alignment: 'left',
          cellTemplate: 'Invoice',
        },
        // {
        //   caption: '',
        //   field: 'action',
        //   isShow: true,
        //   cellTemplate: 'action',
        //   alignment: 'center',
        // },
      ],
      isLoading: false,
      selectedRowsData: [],
      paymentDataSelected: [],
      listClient: [],
      listCashiers: [],
      zoneId: null,
      listPaymentType: [],
      paymentTypeOptions: [],
      listPaymentMethodEInvocie: [],
      checkModuleEInvocie: JSON.parse(localStorage.getItem('module')).find(x => x == "EINVOICE"),
    }
  },
  computed: {
    dataGrid() {
      return this.$refs[dataGridRef].instance
    },
  },
  created() {
    this.getListPayment_RPMI01()
    this.getListClient()
    this.getListPaymentMethodEInvoice()
    this.getConfigInfo_RCOM01()
    this.getListCashier_RCS01()
  },
  methods: {
    _dayjs: dayjs,
    getSelectedData() {
      this.selectedRowsData = this.dataGrid.getSelectedRowsData()
    },
    async getConfigInfo_RCOM01() {
      await booking.getConfigInfo({ KeyGroup: 'BOOKING_CONFIG' }).then(async response => {
        if (response.Status === '200') {
          this.zoneId = response?.Data.filter(item => item.KeyCode === 'BAR_LOCATION_4_ACCOUNTANT')
          return this.getListPaymentType_RPF02()
        }
      })
    },
    async getListPaymentType_RPF02() {
      await booking.api_RPF02({
        ZoneId: this.zoneId.map(item => String(item.KeyValue)),
      }).then(async response => {
        if (response.Status === '200') {
          this.listPaymentType = response?.Data?.PaymentType
          this.paymentTypeOptions = response?.Data?.PaymentType.map(x => ({
            title: x.Name,
            value: x.Code,
          }))
        }
      })
    },
    async getListCashier_RCS01() {
      await booking.api_RCS01().then(response => {
        if (response.Status === '200') {
          this.listCashiers = response?.Data?.Cashier.map(x => ({
            id: x.Id,
            label: x.CashierName,
          }))
        }
      })
    },
    async getListClient() {
      await commonServices
        .getCompany()
        .then(res => {
          if (res.Status === '200') {
            this.listClient = res.Data.Client
          }
        })
    },
    async getListPaymentMethodEInvoice() {
      await invoice
        .api_RPMI04()
        .then(res => {
          if (res.Status === '200') {
            this.listPaymentMethodEInvocie = res.Data.map(x => ({
              title: x.Name,
              value: x.Code,
            }))
          }
        })
    },
    async getListPayment_RPMI01() {
      this.isLoading = true
      await invoice.api_RPMI01(this.search)
        .then(res => {
          if (res) {
            this.listPayment = res.Data.Payment
          }
        })
      this.isLoading = false
    },

    openSidebarMergeService() {
      this.getSelectedData()
      if (this.selectedRowsData.length) {
        this.paymentDataSelected = this.selectedRowsData.map(x => ({
          Id: x.Id,
          BookingType: x.BookingType,
        }))
        this.$root.$emit('bv::toggle::collapse', 'sidebar-merge-service')
      } else {
        this.showToast('error', this.$t('golf_common_please_select_payment'))
      }
    },
    handelEvent(event) {
      if (event.type == 'after_issue_invoice') {
        this.getListPayment_RPMI01()
      }
      if (event.type == 'after_delete_invoice') {
        this.getListPayment_RPMI01()
      }
      if (event.type == 'refresh_list_payment') {
        this.getListPayment_RPMI01()
      }
    },
    exportExcel() {
      const workbook = new Workbook()
      const worksheet = workbook.addWorksheet('Payment')
      exportDataGrid({
        component: this.dataGrid,
        worksheet,
        autoFilterEnabled: true,
        PrintSelectedRowsOnly: true,
        topLeftCell: { row: 4, column: 1 },
        customizeCell: function(options) {
          const { gridCell, excelCell } = options;
          if(gridCell.rowType === 'data') {
            if(gridCell.column.dataField == 'TeeTime') {
              if(gridCell.value.TeeName) {
                excelCell.value = convertUTC(gridCell.value.OpenDateTime,'HH:mm') + ' ' + gridCell.value.TeeName 
              } else {
                excelCell.value = convertUTC(gridCell.value.OpenDateTime,'HH:mm')
              }
            }
            if(gridCell.column.dataField == 'FiscalPaymentDate') {
              excelCell.value =  convertUTC(gridCell.value,'DD/MM/YYYY HH:mm')
            }
            if(gridCell.column.dataField == 'Invoice') {
              if(gridCell.value.length > 0) {
                excelCell.value = JSON.stringify(gridCell.value.map(x => x.InvoiceNo))
              } else {
                excelCell.value = ''
              }
              
            }
      } }
      }).then(cellRange => {
        // header
        const headerRow = worksheet.getRow(2)
        headerRow.height = 30
        worksheet.mergeCells(2, 1, 2, 8)

        headerRow.getCell(1).value = `DANH SÁCH THANH TOÁN (từ ${this.convertUTC(this.search.FromDate, 'DD/MM/YYYY')} đến ${this.convertUTC(this.search.ToDate, 'DD/MM/YYYY')})`
        headerRow.getCell(1).font = { name: 'Segoe UI Light', size: 22 }
        headerRow.getCell(1).alignment = { horizontal: 'center' }

        // footer
        const footerRowIndex = cellRange.to.row + 2
        const footerRow = worksheet.getRow(footerRowIndex)
        worksheet.mergeCells(footerRowIndex, 1, footerRowIndex, 8)
        footerRow.getCell(1).font = { color: { argb: 'BFBFBF' }, italic: true }
        footerRow.getCell(1).alignment = { horizontal: 'right' }
      }).then(() => {
        workbook.xlsx.writeBuffer().then(buffer => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Payment.xlsx')
        })
      }) 
    },
    async openPrintInvoice(data) {
      if(!this.checkModuleEInvocie) { return }
      if (data.column.name != 'action' &&  data.column.type != 'selection' && data.data.BookingType != 'FOLIO_NON_GOLF' && data.data.BookingType != 'FOLIO' && data.data.BookingType != 'FOLIO_HOTEL' && data.column.dataField == 'PaymentCode') {
        if(data.data.Id) {
          const routeData = this.$router.resolve({ name: 'booking-print-invoice', params: { id: data.data.Id } })
          window.open(routeData.href, '_blank')
        }
      }
    },
    openModalViewEInvoice(data) {
      
      this.$refs.sidebarMergeService.viewEInvocie_RIVAT03(data.Id, 'DownloadPDF')
      this.isLoading = true
      setTimeout(() => {
        this.isLoading = false;
    }, 1500); 
    }
  },
}
